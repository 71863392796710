<template>
  <div
    class="navbar d-flex align-center"
    :class="[
      layout === 'template7'
        ? 'justify-space-between pl-5 pr-5'
        : 'justify-center',
    ]"
  >
    <div v-if="layout != 'template7'" class="navbar__language">
      <Icon
        v-if="layout === 'template6'"
        data="@/assets/icons/subNav/icon_hamburger.svg"
        width="25px"
        height="30px"
        @click="drawer = !drawer"
      />
      <div v-else class="global d-flex align-center">
        <!-- 地球 Icon -->
        <Icon
          :data="iconGlobal"
          width="24px"
          height="24px"
          @click="languageListVisible = !languageListVisible"
        />
      </div>
      <!-- 語系清單 -->
      <div
        v-if="languageListVisible"
        class="navbar__language__list d-flex flex-column align-center py-2 bg"
      >
        <div
          v-for="lang in switchLanguages"
          :key="lang.code"
          class="navbar__language__list__item d-flex justify-center align-center my-1 py-1"
          :class="{ active: lang.code === activeLang }"
          @click="settingClientLang(lang.code)"
        >
          <img
            :src="baseImgUrl + lang.icon"
            :alt="lang.default_name"
            width="20px"
            height="20px"
          />
        </div>
      </div>
    </div>

    <!-- 中間 Logo 圖  -->
    <img :src="websiteLogoURL()" :alt="websiteName" class="navbar__logo" />

    <router-link
      v-if="layout === 'template7'"
      class="mail-link"
      :style="{ 'background-color': secondaryClr }"
      to="/user/news"
    >
      <Icon data="@/assets/icons/home/mail.svg" width="15" />
    </router-link>
    <!-- 側選單 -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <SubNav
        v-if="layout === 'template6' && drawer"
        class="sub-nav-box"
        @close-sub-nav="closeSubNav"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import iconGlobal from '@/assets/icons/global.svg'
import SubNav from './subNav/type1.vue'

export default {
  name: 'Navbar',
  components: {
    SubNav,
  },
  data() {
    return {
      subNav: null, // 側選單
      iconGlobal,
      languageListVisible: false, // 顯示語系下拉選單
      activeLang: null, // 當前語系
      drawer: null, // 側選單是否開啟
    }
  },
  computed: {
    ...mapGetters([
      'clientLang', // 前端當前切換語系
      'baseImgUrl',
      'websiteName',
      'theme',
      'layout', // 版型
    ]),
    ...mapState({
      // 可選語言
      switchLanguages: (state) => state.websiteData.lang_available,
      websiteLogo: (state) => state.websiteData.site_params,
    }),
    secondaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.secondary
    },
  },
  methods: {
    ...mapActions(['get_website_theme', 'get_homepage_layout_area']),
    websiteLogoURL() {
      return this.baseImgUrl + this.websiteLogo[`logo_${this.theme || 'dark'}`]
    },
    /**
     * 關閉側選單
     * @date 2024-02-08
     */
    closeSubNav(type) {
      this.drawer = type
    },
    // 設置前端語系
    settingClientLang(clientLang) {
      if (clientLang === this.clientLang) {
        this.languageListVisible = false
        return false
      }

      this.$i18n.locale = clientLang
      sessionStorage.setItem('clientLang', clientLang)
      this.activeLang = clientLang

      // 更新 layout 資料
      this.get_website_theme()
      this.get_homepage_layout_area()
      this.languageListVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 52px;
  position: relative;

  &__language {
    position: absolute;
    left: 10px;

    .global {
      cursor: pointer;

      &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        margin-left: 5px;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #f87248 transparent transparent transparent;
      }
    }

    &__list {
      position: absolute;
      width: 45px;
      border-radius: 10px;
      z-index: 999;

      &__item {
        width: 100%;
        cursor: pointer;

        img {
          object-fit: contain;
        }

        &.active {
          background: rgba(#9f8aff, 0.3);
        }
      }
    }
  }

  &__logo {
    width: auto;
    height: 40px;
    object-fit: contain;
  }
  .v-navigation-drawer {
    position: fixed;
  }
}
</style>
