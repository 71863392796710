
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { gsap } from 'gsap'
import { resStatus } from '@/utils/resUtils'

export default {
  data: () => ({
    overlay: false,
    balanceLoading: false,
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      userAccount: state => state.user.userData.member?.account,
      userBalance: state => state.wallet.main,
      player: state => state.wallet.player.name,
      registerStatus: state => state.websiteData.func_switch.allow_register,
      websiteLogoApi: state => state.websiteData.site_params,

      // line
      lineDisplayName: state => state.line.liff.displayName,
      lineAvatar: state => state.line.liff.avatar,
      userBalanceProgressStatus: state => state.userInfo.mainBalanceProgressStatus,
      // 顯示 vip 相關
      showVip: state => state.websiteData.func_switch.vip,
    }),

    ...mapGetters([
      'currencySymbol',
      'customGradientBgTypeA',
      'style',
      'isMycardDomainService',
      'baseImgUrl',
      'currencySymbol_',
      'isLiffMode',
      'websiteLogo',
      'theme',
    ]),

    lineIsLogin() {
      if (!this.isLiffMode) return false
      return window.liff.isLoggedIn()
    },
  },

  mounted() {
    if (this.isLiffMode) {
      if (this.isLogin) return false
      this.SET_USERINFO_BALANCE_PROGRESS_STATUS(true)
    }
    if (this.isLogin && !this.isLiffMode) {
      this.SET_USERINFO_BALANCE_PROGRESS_STATUS(true)
      this.getUserBalance()
    }
  },

  methods: {
    ...mapActions([
      'get_user_balance',
      'user_retrieve_all_wallet_balance',
      'show_alert',
      'get_user_wallet_list',
    ]),
    ...mapMutations([
      'SET_USERINFO_BALANCE_PROGRESS_STATUS',
      'SET_FREE_BALANCE',
      'SET_PLAYER_BALANCE',
      'SET_LOCKED_WALLET_BALANCE',
    ]),
    /**
     * subNav取得logo圖片
     * @date 2023-02-19
     */
    websiteLogoURL() {
      return this.baseImgUrl + this.websiteLogoApi[`logo_${this.theme || 'dark'}`]
    },
    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
      this.SET_USERINFO_BALANCE_PROGRESS_STATUS(false)
    },

    fastTrade() {
      this.overlay = true
      this.btnAction()
    },

    btnAction() {
      // 設置動畫 gsap
      const btnArea = gsap.fromTo(
        '.btn-grop',
        // from
        {
          x: 244,
        },
        // to
        {
          duration: 0.2, // 運行時間
          x: 0,
          ease: 'none',
        })

      // 播放
      btnArea.play()
    },

    goPageFunc(routerPath) {
      if (this.isLiffMode && this.isMycardDomainService) return false
      this.$router.push({ path: routerPath })
    },

    async retrieveAllWalletBalanceSuccess() {
      await this.getUserBalance()
      this.balanceLoading = false
    },

    retrieveAllWalletBalanceFail() {
      this.show_alert({
        icon: 'fail',
      })
      this.balanceLoading = false
    },

    /**
     * 全部回收
     * - 操作 loading
     * @date 2021-04-14
     */
    async retrieveAllWalletBalance() {
      this.balanceLoading = true
      const res = await this.user_retrieve_all_wallet_balance()
      resStatus(res, this.retrieveAllWalletBalanceSuccess, this.retrieveAllWalletBalanceFail)
    },

    getUserWalletListSuccess(data) {
      const { player, free } = data

      this.SET_FREE_BALANCE(free.find(wallet => wallet.wallet_code === 'free_wallet'))
      this.SET_PLAYER_BALANCE(player.find(wallet => wallet.wallet_code === 'player_balance'))
      this.SET_LOCKED_WALLET_BALANCE(player.find(wallet => wallet.wallet_code === 'locked_balance'))
      this.balanceLoading = false
    },

    walletTransferFail() {
      this.show_alert({
        icon: 'fail',
      })
      this.balanceLoading = false
    },

    // 取得使用者錢包
    async getUserWallet() {
      this.balanceLoading = true
      const res = await this.get_user_wallet_list()
      resStatus(res, this.getUserWalletListSuccess, this.walletTransferFail)
    },
  },
}
